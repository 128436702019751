import { css } from '@emotion/react'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Stack } from 'fitify-ui-onboarding/src/components'

import {
  StyledThresholdBackgroundV2,
  StyledThresholdWrapper,
} from './Threshold.Style'

import { ThresholdProps } from '.'

const ThresholdWrapperV2 = ({
  hasBackground,
  children,
  style,
}: ThresholdProps) => {
  const isChildNull = (children: JSX.Element | EmotionJSX.Element) => {
    if (!children) return true

    const { props } = children

    if (typeof props.children === 'string') {
      return !props.children
    }

    if (
      Array.isArray(props.children) &&
      props.children.some((child: JSX.Element) => Boolean(child))
    ) {
      return false
    }

    return Boolean(children.type === null)
  }

  return (
    !isChildNull(children) && (
      <StyledThresholdWrapper style={style}>
        {hasBackground && <StyledThresholdBackgroundV2 />}
        <Stack
          xs={12}
          css={css`
            align-items: center;
          `}
        >
          {children}
        </Stack>
      </StyledThresholdWrapper>
    )
  )
}

export default ThresholdWrapperV2
